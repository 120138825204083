<template>
  <Message :severity="user.status_salon ? 'info' : 'danger'" icon="pi pi-send">
    <span v-if="user.status_salon == 1">
      Salon Anda Telah di-<b>Approved</b>
    </span>
    <span v-if="user.status_salon == 2"> Salon Anda Telah <b>Aktif</b> </span>
    <!-- <span v-if="user.status_salon == 3">
      Salon Anda Telah di-<b>Tolak </b>
    </span> -->
    <span v-if="user.status_salon == 0">
      Salon Anda Ter- <b> pending </b>
    </span>
  </Message>

  <div class="grid tw-mb-2">
    <div
      v-for="(value, key) in iframe"
      :key="key"
      class="col-12 md:col-6"
      :class="{ 'p-0': key === 'barber_rating' || key === 'list_transactions' }"
    >
      <div
        class="card tw-p-0"
        v-if="key !== 'barber_rating' && key !== 'list_transactions'"
      >
        <div class="flex justify-content-between mb-3"></div>
        <div class="text-900 font-medium text-xl">
          <iframe
            id="iframe"
            :src="value"
            width="100%"
            height="180"
            frameborder="0"
            allowtransparency
          >
          </iframe>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6 tw-hidden">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Transaksi
          </label>
          <i
            class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total transaction with 2 status (paid)',
              class: 'tw-text-sm tw-py-0',
            }"
          >
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            41
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, 0.1)" />
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6 tw-hidden">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Barber
          </label>
          <i
            class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Calculate count of barbers in a salon',
              class: 'tw-text-sm tw-py-0',
            }"
          >
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            15
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, 0.1)" />
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12 md:col-8 md:tw-pr-8">
      <div
        class="card tw-mb-8"
        style="background-color: rgba(230, 241, 248, 1)"
        v-if="
          !user.flagging_salon.service &&
          !user.flagging_salon.barbers &&
          !user.flagging_salon.barber_available
        "
      >
        <div class="tw-flex tw-justify-between">
          <Knob
            v-model="value"
            valueTemplate="{value}%"
            size="60"
            disabled
            class="tw-opacity-100"
          />
          <a
            href="#"
            class="tw-text-black inter-semibold tw-text-sm tw-opacity-60"
          >
            <i class="pi pi-times"></i>
          </a>
        </div>
        <h6 class="tw-text-black tw-mt-4 tw-mb-2 inter-medium">
          Lengkapi Data
        </h6>
        <p class="tw-opacity-60 tw-text-sm tw-mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
        <div class="tw-flex tw-justify-between">
          <router-link
            :to="{ name: nextLink }"
            class="tw-text-blue-light inter-semibold tw-text-sm"
          >
            Lengkapi data
          </router-link>
          <a href="#" class="tw-text-black inter-semibold tw-text-sm">
            Lewati
          </a>
        </div>
      </div>

      <hr
        class="tw-mb-8"
        style="border-top: solid 1px rgba(148, 148, 148, 0.1)"
        v-if="
          !user.flagging_salon.service &&
          !user.flagging_salon.barbers &&
          !user.flagging_salon.barber_available
        "
      />

      <div class="card tw-p-0 tw-overflow-hidden tw-hidden">
        <div class="tw-flex tw-justify-between tw-items-center tw-p-5">
          <div>
            <h6 class="tw-text-black tw-text-lg tw-mt-4 tw-mb-1 inter-semibold">
              Laporan Transaksi
              <i
                class="pi pi-question-circle tw-opacity-60"
                v-tooltip.top="{
                  value: 'Laporan transaksi',
                  class: 'tw-text-sm tw-py-0',
                }"
              >
              </i>
            </h6>
            <p class="tw-text-sm tw-opacity-60">
              Catatan laporan dalam 30 hari terakhir
            </p>
          </div>

          <Calendar
            v-model="dates"
            selectionMode="range"
            :manualInput="false"
            class="tw-h-10 tw-w-60"
            dateFormat="dd M yy"
            placeholder="10/09/2023 - 10/25/202"
          />
        </div>

        <hr
          class="tw-mb-0"
          style="border-top: solid 2px rgba(148, 148, 148, 0.1)"
        />

        <DataTable
          :value="transactions"
          :paginator="false"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          responsiveLayout="scroll"
          scrollable
          scrollHeight="530px"
        >
          <template #empty>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
            >
              Tidak ada data yang ditemukan
            </div>
          </template>
          <template #loading>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
              Loading records, please wait...
            </div>
          </template>
          <Column field="id" header="ID" style="min-width: 2rem"></Column>
          <Column
            header="Invoice"
            field="invoice_number"
            style="min-width: 10rem"
          ></Column>
          <Column
            header="Nama Layanan"
            field="service_name"
            style="min-width: 12rem"
          ></Column>
          <Column
            header="Nama Barber"
            field="barber_name"
            style="min-width: 12rem"
          ></Column>
          <Column header="Harga" field="amount" style="min-width: 10rem">
            <template #body="{ data }">
              {{ formatCurrency(data.amount) }}
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="card tw-p-0 tw-overflow-hidden">
        <iframe
          :src="iframe.list_transactions"
          frameborder="0"
          width="100%"
          height="600"
          allowtransparency
        >
        </iframe>
      </div>
    </div>

    <div
      class="col-12 md:col-4 md:tw-pl-8 md:tw-border-l-2 md:tw-border-gray-200"
    >
      <div class="tw-flex tw-justify-between tw-mb-2">
        <h6 class="tw-text-black tw-text-lg tw-mt-4 tw-mb-2 inter-semibold">
          Rating Rata-Rata Barber
          <i
            class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Count average rating all barber in a salon',
              class: 'tw-text-sm tw-py-0',
            }"
          >
          </i>
        </h6>
        <a href="#" class="tw-text-blue-light inter-medium">
          <i class="pi pi-download"></i>
        </a>
      </div>

      <div class="tw-flex tw-items-center" v-for="n in 5" :key="n">
        <div
          class="tw-w-14 tw-h-14 tw-rounded-full tw-bg-gray-100 tw-flex-shrink-0"
        ></div>
        <div
          class="tw-flex tw-justify-between tw-items-center tw-px-5 tw-py-4 tw-w-full"
        >
          <div>
            <h6 class="tw-black inter-medium">Hanna Korsgaard</h6>
            <p class="tw-opacity-60 inter-regular">260 total reviewer</p>
          </div>
          <div class="tw-flex tw-items-center">
            <i class="pi pi-star-fill tw-text-yellow-400 tw-mr-1"></i>
            <span class="inter-semibold tw-mr-1"> 4.3 </span>
            <span class="inter-regular tw-opacity-60"> dari 5 </span>
          </div>
        </div>
      </div>

      <hr
        class="tw-my-5"
        style="border-top: solid 1px rgba(148, 148, 148, 0.1)"
      />

      <h6 class="tw-text-black tw-text-lg tw-mt-6 tw-mb-2 inter-semibold">
        Statistik Transaksi
      </h6>
      <Chart
        type="bar"
        :data="chartData"
        :options="chartOptions"
        class="h-30rem"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "dashboard-admin-salon",
  props: [
    "transactions",
    "chartData",
    "chartOptions",
    "metabase_url",
    "iframe",
    "user",
  ],
  data() {
    return {
      dates: null,
      value: null,
      nextLink: "",
    };
  },
  mounted() {
    this.calculateCompletion();
  },
  methods: {
    formatCurrency(data) {
      let value = parseInt(data);
      return value.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
    },
    calculateCompletion() {
      this.value = 0;
      if (this.user.flagging_salon.service) {
        this.value += 33.3;
      } else {
        this.nextLink = "salon-services";
      }

      if (this.user.flagging_salon.barbers) {
        this.value += 33.3;
      } else {
        this.nextLink = "barbers";
      }

      if (this.user.flagging_salon.barber_available) {
        this.value += 33.3;
      } else {
        this.nextLink = "barbers";
      }
      this.value = parseFloat(this.value).toFixed(1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
