<template>
  <div class="grid tw-mb-2 tw-hidden">
    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Salon
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total semua salon',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            156
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Pelanggan
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total semua pelanggan',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            15
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Barber
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total semua barber',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            89
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Terdaftar Hari Ini
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total semua terdaftar hari ini',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            12
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="grid tw-mb-2 tw-hidden">
    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Transaksi
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total transaction with 2 status (paid)',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            41
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6 lg:col-3">
      <div class="card tw-p-0">
        <div class="tw-px-6 tw-py-6">
          <label class="inter-medium tw-opacity-60 tw-mr-1">
            Total Faktur
          </label>
          <i class="pi pi-question-circle tw-opacity-60"
            v-tooltip.top="{
              value: 'Total semua faktur',
              class: 'tw-text-sm tw-py-0',
            }">
          </i>
          <h1 class="tw-text-black tw-text-4xl tw-mt-2 tw-mb-2 inter-bold">
            15
          </h1>
        </div>
        <hr style="border-top: solid 1px rgba(148, 148, 148, .1);">
        <div class="text-right tw-px-6 tw-py-4">
          <a href="#" class="tw-text-blue-light inter-semibold">
            <i class="pi pi-download tw-mr-1"></i>
            Download
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="grid tw-mb-2">
    <div class="col-12 sm:col-6 lg:col-3" v-for="(value, key) in iframe" :key="key">
      <div class="card tw-p-0">
        <div class="flex justify-content-between mb-3"></div>
        <div class="text-900 font-medium text-xl">
          <iframe
            id="iframe"
            :src="value"
            width="100%"
            height="180"
            frameborder="0"
            allowtransparency>
          </iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="card tw-p-0 tw-overflow-hidden tw-hidden">
    <div class="tw-p-5">
      <h6 class="tw-text-black tw-text-lg tw-mt-4 tw-mb-1 inter-semibold">
        Laporan Transaksi 
        <i class="pi pi-question-circle tw-opacity-60"
          v-tooltip.top="{
            value: 'Laporan transaksi',
            class: 'tw-text-sm tw-py-0',
          }">
        </i>
      </h6>
      <p class="tw-text-sm tw-opacity-60">
        Catatan laporan dalam 30 hari terakhir
      </p>
    </div>

    <hr class="tw-mb-0" style="border-top: solid 2px rgba(148, 148, 148, .1);">

    <DataTable
      :value="transactions"
      :paginator="false"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="530px"
    >
      <template #empty>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
          Tidak ada data yang ditemukan
        </div>
      </template>
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
      <Column field="id" header="ID" style="max-width: 3rem"></Column>
      <Column header="Invoice" field="invoice_number" style="min-width: 10rem"></Column>
      <Column header="Nama Salon" field="salon_name" style="min-width: 12rem"></Column>
      <Column header="Nama Layanan" field="service_name" style="min-width: 12rem"></Column>
      <Column header="Nama Barber" field="barber_name" style="min-width: 12rem"></Column>
      <Column header="Harga" field="amount" style="min-width: 10rem">
        <template #body="{ data }">
          {{ formatCurrency(data.amount) }}
        </template>
      </Column>
    </DataTable>
  </div>

  <div class="card tw-p-0 tw-overflow-hidden">
    <iframe
      :src="`${metabase_url}/public/question/2561752c-1cf8-48f5-86b1-51bcf5492fb8`"
      frameborder="0"
      width="100%"
      height="600"
      allowtransparency>
    </iframe>
  </div>

  <div class="card tw-p-0 tw-overflow-hidden">
    <div class="tw-p-5">
      <h6 class="tw-text-black tw-text-lg tw-mt-4 tw-mb-1 inter-semibold">
        Statistik Transaksi 
        <i class="pi pi-question-circle tw-opacity-60"
          v-tooltip.top="{
            value: 'Laporan transaksi',
            class: 'tw-text-sm tw-py-0',
          }">
        </i>
      </h6>
      <p class="tw-text-sm tw-opacity-60">
        Catatan 30 hari terakhir
      </p>
    </div>
    <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem tw-mb-5" />
  </div>
</template>
  
<script>
  export default {
    name: 'dashboard-admin-priti',
    props: ['transactions', 'chartData', 'chartOptions', 'metabase_url', 'iframe'],
    data() {
      return {}
    },
    mounted () {},
    methods: {
      formatCurrency(data) {
        let value = parseInt(data)
        return value.toLocaleString('id-ID', {
          style: 'currency', 
          currency: 'IDR', 
          minimumFractionDigits: 0,
        })
      },
    },
  }
</script>
  
<style lang="scss" scoped>

</style>