<template>
  <main class="tw-p-6 md:tw-p-10">
    <div
      class="tw-mb-10 tw-block md:tw-flex tw-items-center tw-justify-between"
    >
      <div class="inter-regular tw-mb-5 md:tw-mb-0">
        <h1 class="tw-text-3xl inter-semibold tw-text-black tw-mb-2">
          Beranda
        </h1>
        <p v-if="user.roles === 'admin_salon'" class="tw-opacity-70">
          Selamat datang kembali, Admin {{ user.salon_name }}!
        </p>
        <p v-else class="tw-opacity-70">
          Selamat datang kembali, Admin Salonial
        </p>
      </div>
      <form @submit.prevent="">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="keyword"
            placeholder="Cari disini"
            class="tw-h-11 tw-w-80 mr-3"
          />
        </span>
      </form>
    </div>

    <div class="" v-if="isAdmin">
      <admin-priti
        :transactions="transactions"
        :chartData="chartData"
        :chartOptions="chartOptions"
        :metabase_url="METABASE_URL"
        :iframe="admin_priti.result_iframe"
      >
      </admin-priti>
    </div>

    <div class="" v-else>
      <admin-salon
        :transactions="transactions"
        :chartData="chartData"
        :chartOptions="chartOptions"
        :metabase_url="METABASE_URL"
        :iframe="admin_salon.result_iframe"
        :user="user"
      >
      </admin-salon>
    </div>

    <div :user="user"></div>
  </main>
</template>

<script>
import { useAuthRole } from "@/composables/auth";
import DashboardAdminSalon from "./components/AdminSalon.vue";
import DashboardAdminPriti from "./components/AdminPriti.vue";

export default {
  setup() {
    const { isAdminPriti, getUser } = useAuthRole();
    return { isAdminPriti, getUser };
  },
  props: ["id"],
  components: {
    "admin-salon": DashboardAdminSalon,
    "admin-priti": DashboardAdminPriti,
  },
  data() {
    return {
      METABASE_URL: process.env.VUE_APP_METABASE_URL,
      chartData: null,
      chartOptions: null,
      keyword: "",
      transactions: [
        {
          id: 1,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 2,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 3,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 4,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 5,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 6,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 7,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 8,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 9,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 10,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 11,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 12,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 13,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 14,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 15,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 16,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 17,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 18,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 19,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 20,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
        {
          id: 21,
          invoice_number: "#INV12313",
          salon_name: "Salon One",
          service_name: "Cuci & Blow",
          barber_name: "Isabella Anderson",
          amount: 250000,
        },
      ],
      admin_priti: {
        metabase_data: {
          salon: 4,
          customer: 5,
          barber: 6,
          amount: 8,
          invoice: 7,
          today_registered: 9,
        },
        result_iframe: {
          salon: "",
          customer: "",
          barber: "",
          amount: "",
          invoice: "",
          today_registered: "",
        },
      },
      admin_salon: {
        metabase_data: {
          barber: 1,
          invoice: 3,
          barber_rating: 12,
          list_transactions: 11,
        },
        result_iframe: {
          barber: "",
          invoice: "",
          barber_rating: "",
          list_transactions: "",
        },
      },
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isAdminPriti();
      if (isAdmin) {
        this.getData(isAdmin);
      }
      return isAdmin;
    },
    user() {
      const user = this.getUser();
      if (user && user.salon_id && user.salon_id !== 0) {
        this.getData(false, user.salon_id);
      }
      return user;
    },
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
  },
  methods: {
    getData(isAdminPriti, salon_id = null) {
      const jwt = require("jsonwebtoken");

      const METABASE_SITE_URL = process.env.VUE_APP_METABASE_URL;
      const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY;

      let payload = {};
      let token = "";

      if (isAdminPriti) {
        for (const [key, value] of Object.entries(
          this.admin_priti.metabase_data
        )) {
          payload = {
            resource: { question: value },
            params: {},
            exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
          };
          token = jwt.sign(payload, METABASE_SECRET_KEY);
          this.admin_priti.result_iframe[key] =
            METABASE_SITE_URL +
            "/embed/question/" +
            token +
            "#bordered=false&titled=false";
        }
      } else {
        for (const [key, value] of Object.entries(
          this.admin_salon.metabase_data
        )) {
          payload = {
            resource: { question: value },
            params: { salon_id: salon_id },
            exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
          };
          token = jwt.sign(payload, METABASE_SECRET_KEY);
          this.admin_salon.result_iframe[key] =
            METABASE_SITE_URL +
            "/embed/question/" +
            token +
            `#bordered=false&titled=${
              key === "barber_rating" || key === "list_transactions"
                ? "true"
                : "false"
            }`;
        }
      }
    },
    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            type: "bar",
            label: "Paid",
            backgroundColor: documentStyle.getPropertyValue("--green-500"),
            data: [50, 25, 12, 48, 90, 76, 42, 50, 25, 12, 48, 90],
            maxBarThickness: 30,
          },
          {
            type: "bar",
            label: "Unpaid",
            backgroundColor: documentStyle.getPropertyValue("--blue-500"),
            data: [21, 84, 24, 75, 37, 65, 34, 21, 84, 24, 75, 37, 65],
            maxBarThickness: 30,
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: textColor,
              boxWidth: 4,
              boxHeight: 4,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
        datasets: {
          bar: {
            borderRadius: 4,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
